export default {
  props: {
    width: {
      type: Number,
      default() {
        return this.$themeSettings.components.FacebookWidgetPage.width
      },
    },
    height: {
      type: Number,
      default() {
        return this.$themeSettings.components.FacebookWidgetPage.height
      },
    },
  },
}
